@import "../../scss/global.scss";

.container {
  margin-top: 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include for-mobile-screen {
    margin: 32px 24px 0 24px;
  }
  @include for-tablet-screen {
    margin: 48px 48px 0 48px;
  }
}

.bigCardsWrap {
  display: flex;
  flex-flow: wrap;
  flex-direction: column;

  .bigCard {
    width: 100%;
    margin: auto;
    cursor: pointer;
    color: $dark;
    height: auto;
    border-radius: 16px;
    padding: 1.5rem;
    display: flex;
    align-items: flex-end;
    transition: 0.4s ease-out;
    box-shadow: 2px 12px 6px rgba(0, 0, 0, 0.2);

    @include for-tablet-screen {
      display: flex;
      width: 60%;
    }

    @include for-mobile-screen {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .bigCard:hover {
    transform: translateY(20px);
  }
  .bigCard:hover:before {
    opacity: 1;
  }
  .bigCard:hover .info {
    opacity: 1;
    transform: translateY(0px);
  }

  .bigCard img {
    width: 400px;
    height: 100%;
    -o-object-fit: cover;
    object-fit: contain;
    border-radius: 16px;

    @include for-tablet-screen {
      width: 60%;
    }
    @include for-mobile-screen {
      width: 60%;
    }
  }
  color: $dark;
  transition: 0.5s;
}

.info {
  h1 {
    margin: 2px;
    text-align: center;

    @include for-mobile-screen {
      font-size: 1.5rem;
    }
  }
  p {
    font-size: 14px;
  }
}

.cardsWrap {
  display: flex;
  flex-flow: row wrap;
  // margin: 4rem 8rem;
  justify-content: center;
  align-items: center;
  gap: 32px;
  @include for-mobile-screen {
    // margin: 32px 24px 0 24px;
  }
}

.card {
  width: 40%;
  height: auto;
  border-radius: 15px;
  padding: 1.5rem;
  background: white;
  position: relative;
  display: flex;
  align-items: flex-end;
  transition: 0.4s ease-out;
  box-shadow: 2px 8px 16px rgba(0, 0, 0, 0.2);
  @include for-mobile-screen {
    width: 100%;
  }
}
.card:hover {
  transform: translateY(20px);
}
.card:hover:before {
  opacity: 1;
}
.card:hover .info {
  opacity: 1;
  transform: translateY(0px);
}
.card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}
.card img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: contain;
  border-radius: 16px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
}
.card .info {
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s;
}
.card .info h1 {
  margin: 0px;
}
.card .info p {
  letter-spacing: 1px;
  font-size: 15px;
  margin-top: 8px;
}

// breakpoints map
$breakpoints: (
  xs: 32rem,
  sm: 48rem,
  md: 72rem,
  lg: 96rem,
  xl: 102rem,
  xx: 120rem,
);

// media query mixin
@mixin break($size) {
  @media (min-width: map-get($breakpoints, $size)) {
    @content;
  }
}

// number of columns variable
$items: 12;

// loop over the breakpoints
@each $key, $value in $breakpoints {
  @for $i from 1 through $items {
    .grid__item--#{$key}-span-#{$i} {
      flex: 0 0 100%;
      @include break($key) {
        flex: 0 0 #{$i / $items * 100%};
      }
    }
  }
}

.active {
  @include active;
}

.viewBtn {
  @include viewBtn;
}

* {
  box-sizing: border-box;
}

body,
html {
  top: 0;
  margin: 0;
  left: 0;
  overflow-x: hidden;
}

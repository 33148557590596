@import "../scss/global.scss";

.headerImg {
  width: 30%;
  @include for-mobile-screen {
    width: 65%;
  }
}

section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: -10px;

  @include for-mobile-screen {
    margin: 0 24px;
  }
}
.title {
  font-size: 40px;
  font-family: $teritaryFont;
  font-weight: 600;
  @include for-mobile-screen {
    margin-top: -12px;
  }
}
.description {
  font-size: 20px;
  font-weight: 400;
  margin-top: -24px;
  text-align: center;
  // font-family: $teritaryFont;
}

.arrowBtn {
  width: 100px;
  margin-top: -20px;
  @include for-mobile-screen {
    width: 70px;
  }
}

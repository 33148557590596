@import url("https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@400;600;700&family=Mulish:wght@400;600&family=Playfair+Display:wght@400;600&display=swap");

$primaryFont: "Mulish", sans-serif, monospace;
$secondaryFont: "Playfair Display", monospace, sans-serif;
$teritaryFont: "Cormorant Infant", serif;

$dark: #565656;
$grey: #747474;
$lightGrey: #f2f2f2;
$link: #468cbe;

body {
  font-family: "Mulish", sans-serif monospace;
  overflow-x: hidden;
  color: #565656;
  a {
    text-decoration: none;
  }
  li {
    list-style: none;
  }

  p {
    font-weight: 600;
    line-height: 1.5;
  }
}

@mixin for-mobile-screen {
  @media screen and (max-width: 960px) {
    @content;
  }
}
@mixin for-tablet-screen {
  @media only screen and (min-width: 769px) and (max-width: 1281px) {
    @content;
  }
}

button {
  padding: 12px 18px 12px 24px;
  background-color: $lightGrey;
  color: $dark;
  border: none;
  border-radius: 100px;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    opacity: 0.4;
    transition: 0.4s;
  }
  img {
    width: 32px;
  }
}

@mixin active {
  color: #fff;
  background-color: #828282;
  transition: 0.4s;
}

@mixin viewBtn {
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: $dark;
    padding: 12px;
    outline: none;
    border: none;
    border-radius: 3px;
    color: $dark;
    font-weight: 500;
    cursor: pointer;
    transition: 0.4s ease;
    @media screen and (max-width: 1078px) {
      padding: 8px;
      font-weight: 400;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin titleFont {
  p {
    font-weight: 400;
  }
}

@mixin linkToFigma {
  a {
    margin-top: 12px;
    display: flex;
    &:hover {
      text-decoration: underline;
      transition: 0.6s;
    }
    img {
      width: 20px;
      margin-right: 4px;
    }
    color: $link;
  }
}
